var label_description = "";

export const package_ = {
  package_type: 1,
  only_recipient: 1,
  signature: 1,
  return: 0,
  insurance: {
    amount: 50000,
    currency: "EUR",
  },
  label_description: label_description,
};

export const package_cb = {
  package_type: 1,
  only_recipient: 1,
  signature: 1,
  return: 0,
  insurance: {
    amount: 50000,
    currency: "EUR",
  },
  label_description: label_description,
};
export const package_sp = {
  package_type: 1,
  only_recipient: 1,
  signature: 1,
  return: 0,
  insurance: {
    amount: 25000,
    currency: "EUR",
  },
  label_description: label_description,
};

export const package_no_insurance = {
  package_type: 1,
  only_recipient: 1,
  signature: 0,
  return: 0,
  label_description: label_description,
};

export const mailbox_package = {
  package_type: 2,
  label_description: label_description,
};

export const digital_stamp = {
  package_type: 4,
  label_description: label_description,
};

export const small_package = {
  package_type: 6,
  only_recipient: 1,
  signature: 1,
  return: 0,
  insurance: {
    amount: 25000,
    currency: "EUR",
  },
  label_description: label_description,
};
